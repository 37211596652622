<script setup>
import store from '@/store/index'
import { useRouter } from '@/utils/vue_helpers'
import { AUTH_LOGOUT } from '@/store/actions/auth'
import { routes } from '@/router/routes'
import { snackbarData, resetSnackbarOnMount } from '@/components/utils/snackbar/logic';
import { onMounted } from 'vue';

resetSnackbarOnMount()

const { router } = useRouter()

async function logUserOut() {
    try {
        await store.dispatch(AUTH_LOGOUT)
        router.value.push(routes.login)
    } catch (error) {
        snackbarData.text = 'Error logging out'
        snackbarData.color = 'red'
        snackbarData.open = true
        router.value.push(routes.login)
    }
}

onMounted(async () => {
    await logUserOut()
})
</script>

<template>
    <v-container>
        <v-row justify-md="center">
            <v-col cols="4">
                <v-card class="pa-6">
                    <div class="primary--text text-center">
                        Logging Out
                    </div>
                    <v-progress-linear class="mt-6" indeterminate />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>